import { useEffect } from "react"
import { parseJSON } from "../../api/api-utils"
import { getEvents, sendFlowEvent, sendFlowEvents } from "../../atoms/state"
import { isWebSocketRequest } from "../../types/flow.types"
import { WsMiddleware } from "./websocket-listener"

const requestMd = WsMiddleware()

const WsRecording = () => {
  useEffect(() => {
    const dispose2 = requestMd.send.before((next, ...args) => {
      const [data, _, ws] = args
      if (typeof data === "string") {
        const obj = JSON.parse(data)
        sendFlowEvent({
          type: "ws-request",
          description: `the application requests to "${ws.url} ${obj.action} ${obj.requestId}"`,
          data: {
            url: ws.url,
            requestId: obj.requestId,
            data,
            json: parseJSON(data),
            messages: [],
          },
        })
      }
      next(...args)
    })

    const dispose1 = requestMd.message.before((next, ...args) => {
      const [data] = args
      if (data.data) {
        const { requestId } = JSON.parse(data.data)
        const ev = getEvents()
          .filter(isWebSocketRequest)
          .find(event => event.data.requestId === requestId)
        if (ev) {
          ev.data.messages = [
            ...ev.data.messages,
            { data: data.data, timestamp: Date.now(), json: parseJSON(data.data) },
          ]
          sendFlowEvents(getEvents())
        }
      }
      next(...args)
    })
    return () => {
      dispose1()
      dispose2()
    }
  }, [])

  return null
}

export { WsRecording }
